var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import DataBox from "~/components/common/data-box.vue";
var CheckNote = /** @class */ (function (_super) {
    __extends(CheckNote, _super);
    function CheckNote() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.loading = {
            state: false
        };
        _this.model = {
            caseId: "",
            commentType: "COMMENT",
            commentContent: "",
            operatorName: "",
            reminderTime: ""
        };
        _this.rules = {
            reminderTime: [
                { required: true, message: "请选择批注时间", trigger: "change" }
            ],
            commentContent: [
                { required: true, message: "请输入批注内容", trigger: "blur" }
            ]
        };
        return _this;
    }
    CheckNote.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    CheckNote.prototype.reset = function () {
        var noteForm = this.$refs["form"];
        noteForm.resetFields();
    };
    CheckNote.prototype.refreshData = function () {
        var _this = this;
        var queryParamsModel = {
            caseId: this.rowData.caseId,
            commentTypeList: ["REPORT_POSTIL"]
        };
        this.loading.state = true;
        this.caseFollowRecordService.getCommentByType(queryParamsModel, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CheckNote.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CheckNote.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], CheckNote.prototype, "caseFollowRecordService", void 0);
    __decorate([
        State
    ], CheckNote.prototype, "userData", void 0);
    __decorate([
        Prop()
    ], CheckNote.prototype, "currentComment", void 0);
    __decorate([
        Prop()
    ], CheckNote.prototype, "rowData", void 0);
    __decorate([
        Emit("close")
    ], CheckNote.prototype, "close", null);
    CheckNote = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], CheckNote);
    return CheckNote;
}(Vue));
export default CheckNote;
